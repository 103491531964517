import React from 'react';
import Layout from '../../components/common/Layout';
import SectionSideText from './SectionSide1.tsx';
import SectionSideForm from './SectionSide2.tsx';
import LetschatHeader from '../letsChat/Header.tsx';
import '../../styles/app.css';
import { Row, Col } from "antd"
const SPANS = {
   span: 23,
   xxl: 23,
   xl: 22
}

const LetsChart = () => {
   return (
      <Layout hideHeader title='Lets Chat'>
         <LetschatHeader />
         <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col {...SPANS} style={{margin: 'auto' }} span={20}>
               <div className='letsChartMain'>
                  <SectionSideText className="order-2 lg:order-1" />
                  <SectionSideForm className="order-1 lg:order-2" />
               </div>
            </Col>
         </Row>

      </Layout>
   )
};

export default LetsChart;